.btn {
    text-align: center;
    border-radius: 4px;
    padding: 4px 80px;
    
}

.btn-primary {
    text-align: center;
}

.diadibi-pause{
    background: #ffffff;
    border: 1pt solid #000;
    border-radius: 10px;
    min-height: 50px;
    width: 100px;
    padding: 10px;
    margin-bottom:20px;
}

.diadibi-next{
    width:100%;
    text-align: center;
    margin-bottom: 20px;
}

.diadibi-image{
    width:100%;
}

.tree-item{
    font-size: 1.125em;
    cursor: pointer;
}

.tree-item:hover{
    color: #0000ff;
}


.diadibi-content{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;
}
.diadibi-tag{
    position: absolute;
    right: -20px;
    top: -20px;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1pt solid #000;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.diadibi-tag:hover{
    background: #000;
    color: #fff;
}


.diadibi-choice {
    width: 100%;
    background: #c0c0c0;
    color: #000;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    cursor: pointer;
}


.diadibi-icons{
    display: inline-flex;
    vertical-align: top;
    margin-right: 5px;
}

.diadibi-choice:hover{
    background: #e0e0e0;
}

.diadibi-choice-selected{
    background: #cee0f4;
}

.diadibi-check-line{
    width: 100%;
    margin-left: 10px;
    margin-top: 10px;

    display: inline-flex;
    vertical-align: middle;

}

.diadibi-item-feedback{
    background: #fff;
    color: #000;
    border: 1pt solid #a0a0a0;
    margin-left: 20px;
    padding: 8px;
}


.diadibi-result {
    width: auto;
    border-radius:5px;
    background: #fff;

    display: inline-flex;
    vertical-align: middle;
    margin-right: 20px;

    font-size: 1.4em;
}


.diadibi-milestone{
    display: inline-flex;
    background: #f0f0f0;
    border: 1pt solid #a0a0a0;
    text-align: center;
}

.diadibi-drag-element{
    width: auto; 
    display: inline-block;
    padding: 10px;
    border: 1pt solid #a0a0a0;
    background: gold;
    border-radius: 5px;
    cursor: grab;
}

.diadibi-drag-element:active{
    cursor: grabbing;
}


.diadibi-drop-zone{
    width: 50%;
    position: relative;
    /*    border: 1pt dotted #a0a0a0;*/
    border-radius: 10px;
    background: #fffff0;
    min-width: 100px;
    min-height: 80px;
    padding: 20px;
    border-style: dotted;
}

.diadibi-drag-zone {
    width: 100%;
    min-height: 100px;
    background: #f0f0f0;
    padding: 20px;
}


.diadibi-badge{
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 4em;
}


.diadibi-top-text{
    /*    font-size: 2em; */
    display: inline-flex;
    /*    font-weight: bold;*/
}


.diadibi-page{
    width: 100%;
    height: 100%;
    background: #fcfcfc;
}



.diadibi-close-btn {
    position: absolute;
    width:26px;
    height: 26px;
    background: #000;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .2s; /* Animation */
    z-index: 1;
    right: -10px;
    top: -10px;

}

.diadibi-close-btn:hover{
    transform: scale(1.25);
}


.diadibi-btn {
    position: absolute;
    width:26px;
    height: 26px;
    background: #fff;
    color: #1E90FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .2s; /* Animation */
    z-index: 1;
}

.diadibi-btn:hover{
    transform: scale(1.5);
}


.diadibi-image-text{
    position: absolute;
    width: 45%;
    background: #fff;
    color: #000;
    border: 1pt solid #000;
    padding: 10px;
    z-index: 110;
    display: none;
}


.diadibi-disable{
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    z-index: 10;
    background: rgba(255,255,255,0.5);
    display: none;
}


.diadibi-hotmap{
    position: relative;
}


.diadibi-level-0 {
    margin: 10px;
    border: 2px solid #a0a0a0;
    width: calc(100%-20px);
    border-radius: 10px;
}

.diadibi-level-0:hover{
    border: 2px solid #FF6D0A;
}

.diadibi-level0-head {
    width: 100%;
    background: #1E90FF;
    height: 100px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}


.diadibi-level1-element{
    margin-bottom: 10px;
    margin-top: 5px;
}


.diadibi-level1-head {
    width: 100%;
    background: #f4f4f4;
    border-radius: 20px 20px 20px 20px;
    cursor: pointer;
    color: #000;
    width: calc(100%-40px);
    min-height: 80px;
    position: relative;
    font-size: 1.25em;
    cursor: pointer;
    border: 2px solid #ffffff;
}

.diadibi-level1-body {
    text-align: left;
    border-left: 8px solid #b0b0b0;
    margin-left: 20px;
    padding-left: 20px;
}



.diadibi-level1-head:hover{
    border: 2px solid #FF6D0A;
}

.diadibi-level0-line {
    width: 100%;
    height: 50px;
    background: #fff;
    text-align: center;
    border-radius: 0px 0px 8px 8px;
    font-size: 1.5em;
    cursor: pointer;
}

.diadibi-level0-line:hover{
    color: #0000f0;
    background: #f8f8f8;
}



.diadibi-bold {
    font-weight: bold;
}




.diadibi-level0-body{
    width: 100%;
    height: auto;
    background: #fff;
    text-align: center;
    border-radius: 0px 0px 8px 8px;
    padding: 10px;

}


.diadibi-level2-head {
    width: 100%;
    font-size: 1em;
    color: #a0a0a0;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.diadibi-level2-head:hover{
    border-left: 2pt solid #FF6D0A;
}

.diadibi-level2-head-active {
    width: 100%;
    font-size: 1em;
    color: #000;
    padding-left: 10px;
    border-left: 2pt solid #FF6D0A;
    margin-top: 5px;
    margin-bottom: 5px;

}

.diadibi-level2-head:hover{
    color: #000;
}


.diadibi-expand {
    position: absolute;
    left: 30px;
    margin-top: 5px;
    background: #d0d0d0;
    border-radius: 10px;
    width: 32px;
    height: 32px;
    display: inline;
}




.diadibi-expand:hover{
    background: #b0b0b0;
}


.diadibi-level1-expand{
    position: absolute;
    left: 0px;
    background: #d0d0d0;
    border-radius: 18px 0px 0px 18px;
    width: 40px;
    height: 100%;
    display: inline;
    vertical-align: middle;
    text-align: center;
    padding-top: 6px
}


.diadibi-level1-expand:hover{
    background: #b0b0b0;
}


.diadibi-level0-head>img{
    border-radius: 8px 8px 0 0;
}

.diadibi-skill-header{
    width: 100%;
    text-align: left;
    font-size: 2em;
    cursor: pointer;
    padding-left: 10px;
    font-weight: bold;
}

.diadibi-skill-header:hover{
    color:#0000f0;
}


.diadibi-level1-title1 {
    display: inline-block;
    width: 100%;
    border-radius: 18px;
    padding-left: 40px;
}

.diadibi-level1-title2 {
    position: absolute;
    width: 100%;
    border-radius: 18px; 
}



.diadibi-reset{
    position: absolute;
    right: 0;
    top: 0;
    line-height: 95px;
    height: 95px;
    width: 100px;
    background: #ffffff;
    text-align: center;
}

.diadibi-reset-icon{
    font-size: 3.5em;
    line-height: 90px;
    font-weight: bold;
}


.diadibi-active-title{
    border: 2pt solid #FF6D0A;
}

.diadibi-active{
    border: 2pt solid #FF6D0A;
}

/* Top center text */
.diadibi-text-box {
    position: absolute;
    border:  2pt solid #FF6D0A;
    border-radius: 10px;
    background-color: gray;
    /*   color: white; 
    style="top=30%; left=50%;position=absolute; border=2pt solid wheit; background-color= transparent"*/
    /* ;position:absolute; border:2pt solid #FF6D0A; border-radius:10px;background-color: gray"   */

}
.diadibie-progress-status{
    width: 100%;
    background-color: #ddd;
    border-radius: 8px;
}

.diadibie-progress-bar{
    width: 25%;
    height: 35px;
    background-color: #4CAF50;
    border-radius: 8px;
    text-align: center;
    line-height: 32px;
    color: black;
}
.diadibi-level1-progress-bar {
    position: absolute;
    background-color: greenyellow;
    border-radius: 20px;
    opacity: 70%;
    /*     width: 50%;
    */    right: 32px;
    width:300px;height:30px
}